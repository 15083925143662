/**
 * Created
 *
 * @2019/07/24
 */

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout activeMenu="index">
    <SEO title="Home" />

    <div className="banner_w3lspvt position-relative">
      <div className="container">
        <div className="d-md-flex">
          <div className="w3ls_banner_txt">
            <h3
              className="w3ls_pvt-title"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              Are you ready to become a <br />
              <span>Coding Champ?</span>
            </h3>
            <p
              className="text-sty-banner"
              data-sal="fade"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              Coding Champs is an online coding school that provides a safe and
              fun platform for kids of age 7-15 to learn to code and prepare
              them for the future of technology with our interactive courses
              ranging from Beginner level to advance level.{" "}
            </p>
            <div
              className="row"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              {/* <a
                href="https://ultronele.netlify.com"
                target="_blank"
                rel="noopener noreferrer"
                className="btn button-style mt-md-5 mt-4 read-more"
              >
                Book a FREE trial class
              </a> */}
              <a
                href="/contact"
                style={{ marginLeft: `40px` }}
                rel="noopener noreferrer"
                className="btn button-orange mt-md-5 mt-4 read-more"
              >
                Book a FREE trial class
              </a>
            </div>
          </div>
          <div className="banner-img w-75 text-center">
            <img src="images/front.png" alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="icon-effects-w3l">
        <img
          src="images/shape1.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-one"
        />
        <img
          src="images/shape2.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-two"
        />
        <img
          src="images/shape3.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-three"
        />
        <img
          src="images/shape5.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-four"
        />
        <img
          src="images/shape4.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-five"
        />
        <img
          src="images/shape6.png"
          alt=""
          className="img-fluid shape-wthree shape-w3-six"
        />
      </div>
    </div>

    <div className="what bg-li py-5" id="what">
      <div className="container py-xl-5 py-lg-3">
        <h3
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
          className="tittle text-center font-weight-bold"
        >
          Our <span className="title-span">Courses</span>
        </h3>
        <div className="row about-bottom-w3l text-center mt-4">
          <div className="col-lg-4 about-grid">
            <div className="about-grid-main">
              <img
                src="images/roblox-logo.png"
                alt=""
                className="img-fluid w-50 m-1"
              />
              <div className="card-body">
                <h4 className="my-4">Game Development</h4>
                <p>
                  It is an introductory level course that will help kids to
                  create and publish their first game on Roblox.com
                </p>
              </div>
              <a href="/courses/#roblox" class="button-w3ls btn mt-sm-5 mt-4">
                Read More
              </a>
            </div>
          </div>
          <div className="col-lg-4 about-grid my-lg-0 my-5">
            <div className="about-grid-main">
              <img
                src="images/pythonlogo.png"
                alt=""
                className="img-fluid w-50 m-2"
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Python Programming</h4>
                </div>
                <p>
                  Python is a powerful, expressive programming language that’s
                  easy to learn and fun to use!
                  {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                </p>
              </div>
              <a href="/courses/#python" class="button-w3ls btn mt-sm-5 mt-4">
                Read More
              </a>
            </div>
          </div>
          <div className="col-lg-4 about-grid">
            <div className="about-grid-main">
              <img
                src="images/web-dev.png"
                alt=""
                className="img-fluid w-50 m-2"
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">
                    Web <br /> Development
                  </h4>
                </div>
                <p>
                  Introduction to HTML, CSS, and JavaScript to help the kids
                  develop and host their own site
                </p>
              </div>
              <a href="/courses/#web" class="button-w3ls btn mt-sm-5 mt-4">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="midd-w3 py-4" id="faq">
      <div className="container py-xl-3 py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Why <span className="title-span">Coding Champs?</span>
        </h3>
        <p
          className="mt-4 text-center"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          We value your hard-earned money and time, therefore our goal is to
          provide the best quality of service on a limited budget to make
          education affordable and accessible for all.
        </p>
        <div className="row about-bottom-w3l text-center mt-4">
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/smart-boy.jpg"
                alt=""
                className="img-fluid w-50 m-1"
              />
              <div className="card-body">
                <h4 className="my-4">Kid's Learning & Growth</h4>
                <p>
                  To ensure kids' learning and growth we give them homework
                  after each class.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid my-lg-0 my-5">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/group.jpg"
                alt=""
                className="img-fluid m-1"
                style={{ width: "330px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">1:1 Classes</h4>
                </div>
                <p>
                  Some kids learn at their own pace, therefore, we provide
                  private 1:1 classes for better learning and understanding.
                  {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/attention.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "180px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Indiviual Attention</h4>
                </div>
                <p>
                To give individual attention to all of our students, we keep our group class limited to 3-4 kids.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row about-bottom-w3l text-center mt-4">
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/teacher.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "170px" }}
              />
              <div className="card-body">
                <h4 className="my-4">Book Meeting with Teachers</h4>
                <p>
                Book a meeting with the Teacher to get direct feedback on your kid's progress.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid my-lg-0 my-5">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/coin.jpg"
                alt=""
                className="img-fluid m-1 w-50"
                // style={{ width: "330px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Lowest Price</h4>
                </div>
                <p>
                  We offer the Lowest Price to make learning affordable to
                  everyone.
                  {/* Staring from the fundamentals of python programming language (level 1) to adavnce level (level 2)  */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 about-grid">
            <div>
              <img
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="1500"
                data-sal-easing="ease-out-back"
                src="images/siblings.jpg"
                alt=""
                className="img-fluid"
                style={{ width: "280px" }}
              />
              <div className="card-body">
                <div>
                  <h4 className="my-4">Siblings Discount</h4>
                </div>
                <p>
                Get $50 off as a sibling discount because learning is better when together.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-lg-6 about-right-faq">
            <ul className="w3l-right-book mt-4">
              <li>We ensure kid's growth and learning.</li>
              <li>
                Both private and group classes available at affordable prices.
              </li>
              <li>Financial Aid available for Low-Income parents.</li>
              <li>Indiviual attention to students.</li>
              <li>Book meeting with Teacher to get direct Feedback.</li>
            </ul>
          </div>
          {/* <div className="col-lg-6 left-wthree-img text-right">
            <img src="images/1.png" alt="" className="img-fluid" />
          </div> 
        </div> */}
      </div>
    </section>
    <section className="banner-bottom-w3layouts bg-li  py-5" id="services">
      <div className="container py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Student's <span className="title-span">Projects</span>
        </h3>
        {/* <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
          UltronEle offers a minimal elearning tool and flexible services for a
          variety of user scenarios, even if no budget or no IT knowledge.
        </p> */}
        <div className="row pt-lg-4 ">
          <div className="container">
            <div className="row">
              {/** feature item 1. */}
              <div className="col-md-6 col-lg-6 col-xs-12">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon">
                    {/* <i className="fas fa-rocket"></i> */}
                  </div>
                  <div className="services-content">
                    <h3 className="feature-title text-center">
                      <a href="# ">Obby Game by Abdullah</a>
                    </h3>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?height=285&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F621443002935048%2F&show_text=false&width=560&t=0"
                        className="img-fluid w-100 m-2 responsive-iframe"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div>
                    <p>
                      Meet our cutest and youngest champ Abdullah 👨🏻‍💻 who despite
                      being 7 years old is doing wonders in the world of Roblox.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon"></div>
                  <div className="services-content">
                    <h3 className=" text-center">
                      <a href="#">Python Guessing Game By Mohanad</a>
                    </h3>

                    <div className="iframe-container">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?height=350&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F463207542058344%2F&show_text=false&width=560&t=0"
                        // width="560"
                        // height="285"
                        // style="border:none;overflow:hidden"
                        className="img-fluid w-100 m-2 responsive-iframe"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div>

                    <p>
                      Watch our champ "Mohanad Kuhail" explaining and
                      demonstrating the Number Guessing game he made in Python
                      programming language.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12 offset-lg-3">
                <div
                  className="services-item wow fadeInRight"
                  data-wow-delay="0.3s"
                >
                  <div className="icon"></div>
                  <div className="services-content">
                    <h3 className=" text-center">
                      <a href="#">Web Portfolio by Andrew</a>
                    </h3>
                    <div className="iframe-container">
                      <iframe
                        src="https://www.facebook.com/plugins/video.php?height=262&href=https%3A%2F%2Fwww.facebook.com%2Fcodingchamps.ca%2Fvideos%2F1016156739057090%2F&show_text=false&width=560&t=0"
                        // width="560"
                        // height="285"
                        // style="border:none;overflow:hidden"
                        className="img-fluid w-100 m-2 responsive-iframe"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                        allowFullScreen="true"
                      ></iframe>
                    </div>

                    <p>
                      A quick demo of the web portfolio made by our very
                      talented 11 years old student Andrew Jacob. <br />
                      <b>Link:</b>{" "}
                      <a
                        target="_blank"
                        href="https://jacobvarughese.me/Andrew-Jacob-Portfolio/"
                      >
                        {" "}
                        Andrew's Website
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="banner-bottom-w3layouts py-5" id="services">
      <div className="container py-xl-5 py-lg-3">
        <h3
          className="tittle text-center font-weight-bold"
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-duration="1500"
          data-sal-easing="ease-out-back"
        >
          Parent's <span className="title-span">Feedback</span>
        </h3>
        {/* <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
          UltronEle offers a minimal elearning tool and flexible services for a
          variety of user scenarios, even if no budget or no IT knowledge.
        </p> */}
        <div className="row pt-lg-4 ">
          <div className="col-lg-4 text-center">
            <img
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1500"
              data-sal-easing="ease-out-back"
              src="images/test-1.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
          <div className="col-lg-4 text-center my-lg-0 my-3">
            <img
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1500"
              data-sal-easing="ease-out-back"
              src="images/test-2.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
          <div className="col-lg-4 text-center">
            <img
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1500"
              data-sal-easing="ease-out-back"
              src="images/test-3.png"
              alt=""
              className="img-fluid w-100 m-2"
            />
          </div>
        </div>
        {/* <a
          href="price"
          className="btn button-style button-style-2 mt-sm-5 mt-4 read-more"
        >
          Read More
        </a> */}
      </div>
    </section>

    

    {/* <section className="bottom-count py-5 bg-li" id="stats">
      <div className="container py-xl-5 py-lg-3">
        <div className="row">
          <div className="col-lg-5 left-img-w3ls">
            <img src="images/b2.png" alt="" className="img-fluid" />
          </div>
          <div className="col-lg-7 right-img-w3ls pl-lg-4 mt-lg-2 mt-4">
            <div className="bott-w3ls mr-xl-5">
              <h3 className="title-w3 text-bl mb-3 font-weight-bold">
                Some of Product Real Facts
              </h3>
              <p className="title-sub-2 mb-3">
                We measure our products excellence in following aspects:
              </p>
              <p>
                Note: these data are collected in the phase of v1.0 release
                candidate, it&#39;ll increase with the evolvement of the
                product.{" "}
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-sm-4 count-w3ls">
                <h4>1000+</h4>
                <p>Hours Development</p>
              </div>
              <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                <h4>10+</h4>
                <p>Templates</p>
              </div>
              <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                <h4>10+</h4>
                <p>Built-in Courses</p>
              </div>
            </div>
            <div className="row mt-sm-5 mt-4">
              <div className="col-sm-4 count-w3ls">
                <h4>5+</h4>
                <p>Built-in Categories</p>
              </div>
              <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                <h4>4+</h4>
                <p>Generator for content</p>
              </div>
              <div className="col-sm-4 count-w3ls mt-sm-0 mt-3">
                <h4>~30s</h4>
                <p>Build time</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* <section className="partners py-5" id="partners">
      <div className="container py-xl-5 py-lg-3">
        <h3 className="tittle text-center font-weight-bold">
          Product Infrastructures
        </h3>
        <p className="sub-tittle text-center mt-3 mb-sm-5 mb-4">
          we utilize the power of mature and emerging internet hosting and build
          platform to fast deliver our product, service, content.
        </p>
        <div className="row grid-part text-center pt-4">
          <div className="col-md-2 col-4">
            <div className="parts-w3ls">
              <a href="#">
                <i className="fab fa-github"></i>
              </a>
              <h4>Github</h4>
            </div>
          </div>
          <div className="col-md-2 col-4">
            <div className="parts-w3ls">
              <a href="#">
                <i className="fab fa-react"></i>
              </a>
              <h4>ReactJS</h4>
            </div>
          </div>
          <div className="col-md-2 col-4">
            <div className="parts-w3ls">
              <a href="#">
                <i className="fab fa-node-js"></i>
              </a>
              <h4>NodeJS</h4>
            </div>
          </div>
          <div className="col-md-2 col-4 mt-md-0 mt-4">
            <div className="parts-w3ls">
              <a href="#">
                <i className="fab fa-markdown"></i>
              </a>
              <h4>Markdown</h4>
            </div>
          </div>
          <div className="col-md-2 col-4 mt-md-0 mt-4">
            <div className="parts-w3ls">
              <a href="#">
                <span className="fa fa-skyatlas"></span>
              </a>
              <h4>GatsbyJS</h4>
            </div>
          </div>
          <div className="col-md-2 col-4 mt-md-0 mt-4">
            <div className="parts-w3ls">
              <a href="#">
                <span className="fa fa-ravelry"></span>
              </a>
              <h4>Netlify</h4>
            </div>
          </div>
        </div>
      </div>
    </section> */}
  </Layout>
)

export default IndexPage
